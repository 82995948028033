<template>
  <div style="position: relative;width:100%;height:100%;">
    <div :id="'showMap'+UUID" style="width:100%;height:100%"></div>
    <div v-if="updateBut" class="button">
      <Button @click="$emit('update')">修改</Button>
    </div>
  </div>
</template>

<script>
let maps;
export default {
  name: 'showMap',
  data() {
    return {
      polygon: null,
      map: null,
      area: null,
      path: null,
      UUID: null,
      center: null,
    };
  },
  props: {
    updateBut: {
      default: false,
    },
    paths: {
      type: String,
      default: null,
    },
    area0: {
      type: String,
      default: null,
    },
    center0: {
      default: null,
    },
  },
  mounted() {
    if (!maps) {
      maps = {};
    }
    this.init();
  },
  beforeUnmount() {
    if (maps[this.UUID]) {
      maps[this.UUID].destroy();
      maps[this.UUID] = null;
    }
  },
  created() {
    this.UUID = Math.floor(Math.random() * 1000000000);
  },
  methods: {
    init() {
      maps[this.UUID] = new AMap.Map('showMap' + this.UUID, {
        resizeEnable: true,
        center: [116.400274, 39.905812],
        layers: [
          new AMap.TileLayer.RoadNet(),
          new AMap.TileLayer.Satellite(),
        ],
        zoom: 5,
      });
      this.draw();
    },
    draw() {
      if (this.paths) {
        this.path = JSON.parse(this.paths);
      }
      if (this.area0) {
        this.area = this.area0;
      }

      if (this.center0 && this.center0[0] && this.center0[1]) {
        this.center = this.center0;
      }

      if (this.path && this.path.length > 0) {

        if (maps[this.UUID]) {
          maps[this.UUID].clearMap();
        }

        let list = [];
        this.path.forEach(item => {
          list.push([item.R, item.Q]);
        });

        let polygon = new AMap.Polygon({
          path: list,
          fillColor: '#00b0ff',
          strokeColor: '#80d8ff',
          fillOpacity: 0.4,
        });
        maps[this.UUID].add(polygon);

        let text = new AMap.Text({
          position: new AMap.LngLat(this.path[0].R, this.path[0].Q),
          text: '区域面积' + Number(this.area).toFixed(2) + '亩',
          offset: new AMap.Pixel(-20, -20),
        });
        maps[this.UUID].add(text);

        // 缩放地图到合适的视野级别
        maps[this.UUID].setFitView(polygon);

      } else {
        maps[this.UUID].clearMap();
        new AMap.Marker({
          map: maps[this.UUID],
          position: this.center,
        });
        maps[this.UUID].setZoomAndCenter(15, this.center);
      }

    },
    update(info) {
      if (info) {
        this.area = info.area;
        this.path = info.path;
        this.center = info.center;
        if (info.area && info.path) {
          maps[this.UUID].clearMap();
          this.draw();
        } else {

          maps[this.UUID].clearMap();
          let marker = new AMap.Marker({
            map: maps[this.UUID],
            position: info.center,
          });
          maps[this.UUID].setZoomAndCenter(15, info.center);

        }
      }
    },
  },
};
</script>

<style scoped>
.button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
</style>
